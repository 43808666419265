import FAQ1 from './FAQ1';
import FAQ2 from './FAQ2';
import FAQ3 from './FAQ3';
import FAQ4 from './FAQ4';
import FAQ5 from './FAQ5';
import FAQ6 from './FAQ6';
import FAQ7 from './FAQ7';
import FAQjson from './FAQjson';

export { FAQ1, FAQ2, FAQ3, FAQ4, FAQ5, FAQ6, FAQ7, FAQjson };
