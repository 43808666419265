import Testimonial1 from './Testimonial1';
import Testimonial2 from './Testimonial2';
import Testimonial3 from './Testimonial3';
import Director from './Director';
import Testimonial5 from './Testimonial5';
import Testimonial6 from './Testimonial6';
import Testimonial7 from './Testimonial7';
import Testimonial8 from './Testimonial8';
import Testimonial9 from './Testimonial9';
import Testimonial10 from './Testimonial10';
import Testimonial11 from './Testimonial11';
import Testimonial12 from './Testimonial12';
import Testimonial13 from './Testimonial13';
import Testimonial14 from './Testimonial14';
import Testimonial15 from './Testimonial15';
import Testimonial16 from './Testimonial16';
import Testimonial17 from './Testimonial17';
import Testimonial18 from './Testimonial18';
import Testimonial19 from './Testimonial19';
import Testimonial20 from './Testimonial20';
import Testimonial21 from './Testimonial21';

export {
  Testimonial1,
  Testimonial2,
  Testimonial3,
  Director,
  Testimonial5,
  Testimonial6,
  Testimonial7,
  Testimonial8,
  Testimonial9,
  Testimonial10,
  Testimonial11,
  Testimonial12,
  Testimonial13,
  Testimonial14,
  Testimonial15,
  Testimonial16,
  Testimonial17,
  Testimonial18,
  Testimonial19,
  Testimonial20,
  Testimonial21
};
