// used in the testimonial-1 block
export const testimonialList1 = [
  {
    id: '0',
    name: 'Coriss Ambady',
    designation: 'Financial Analyst', 
    review: 'Cum sociis natoque penatibus et magnis dis parturient montes.',
    image: '/img/photos/sp1.jpg',
    columnClasses: 'col-xl-5 align-self-end'
  }, 
  {
    id: '1',
    name: 'Coriss Ambady',
    designation: 'Financial Analyst', 
    review: 'Cum sociis natoque penatibus et magnis dis parturient montes.',
    image: '/img/photos/sp1.jpg',
    columnClasses: 'col-xl-5 align-self-end'
  }
];

export const testimonialList2 = [
  {
        
    "image": "/img/clients/testimonials/itv.png",
    "alt": "Web Development",
    "review": "Conscientious... flexible and relaxed, difficult to fluster and real drive to deliver... great value for money.",
    "name": "Paul Clark",
    "designation": "Controller, ITV Online Pay & Interactive"
},
{
    
    "image": "/img/clients/testimonials/Hogarth-Worldwide.png",
    "alt": "Web Development",
    "review": "His creative and thorough thinking has not only brought great ideas to the table but ultimately saved us time and money.",
    "name": "Gabriela Martinez-Campbell",
    "designation": "Account Director, Hogarth/Today at Apple"
},
{
    
    "image": "/img/clients/testimonials/bbc.png",
    "alt": "Web Development",
    "review": "Committed to delivery, good at client liaison, charges reasonable rates and tailors suggestions to the budget available. We wouldn't hesitate to use him again.",
    "name": "Mark Harisson",
    "designation": "Director of Technology Futures Group, BBC Engineering"
}, 
{
    
    "image": "/img/clients/testimonials/pegasus-medical.png",
    "alt": "Web Development",
    "review": "Reliable and excellent resource, instantly instrumental in large complex projects with an extremely quick turn around... patient, flexible and understood our requirements. I'd look forward to working with him again.",
    "name": "Kat Thay",
    "designation": "Pegasus Medical"
},
{
    
    "image": "/img/clients/testimonials/the-marketing-store.png",
    "alt": "Web Development",
    "review": "James is an outstanding and versatile developer with a wealth of experience across multiple FE technologies. He is able to quickly grasp project requirements and propose solution options whenever I have worked with him. Highly recommended.",
    "name": "Dimith De Alwis",
    "designation": "The Marketing Store"
},
];

// used in the testimonial-13 block
export const testimonialList3 = [
  {
    id: 1,
    name: 'Coriss Ambady',
    image: '/img/avatars/te1.jpg',
    designation: 'Financial Analyst',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta
    felis euismod semper. Cras justo odio consectetur adipiscing dapibus.`
  },
  {
    id: 2,
    name: 'Cory Zamora',
    image: '/img/avatars/te2.jpg',
    designation: 'Marketing Specialist',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta
    felis euismod semper. Cras justo odio consectetur adipiscing dapibus.`
  },
  {
    id: 3,
    name: 'Barclay Widerski',
    image: '/img/avatars/te3.jpg',
    designation: 'Sales Specialist',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta
    felis euismod semper. Cras justo odio consectetur adipiscing dapibus.`
  }
];

// used in the testimonial-14, testimonial-15 block
export const testimonialList4 = [
  {
    id: 1,
    name: 'Coriss Ambady',
    designation: 'Financial Analyst',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta. Cras mattis consectetur.`
  },
  {
    id: 2,
    name: 'Cory Zamora',
    designation: 'Marketing Specialist',
    review: `Fusce dapibus, tellus ac cursus tortor mauris condimentum fermentum massa justo sit amet purus fermentum.`
  },
  {
    id: 3,
    name: 'Nikolas Brooten',
    designation: 'Sales Manager',
    review: `Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor eu rutrum. Nulla vitae libero.`
  },
  {
    id: 4,
    name: 'Coriss Ambady',
    designation: 'Marketing Manager',
    review: `Etiam adipiscing tincidunt elit convallis felis suscipit ut. Phasellus rhoncus eu tincidunt auctor nullam rutrum.`
  }
];

// used in the testimonial-16 block
export const testimonialList5 = [
  {
    id: 1,
    name: 'Coriss Ambady',
    designation: 'Financial Analyst',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis
    euismod semper. Cras justo odio.`
  },
  {
    id: 2,
    name: 'Cory Zamora',
    designation: 'Marketing Specialist',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis
    euismod semper. Cras justo odio.`
  },
  {
    id: 3,
    name: 'Nikolas Brooten',
    designation: 'Sales Manager',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis
    euismod semper. Cras justo odio.`
  }
];

// used in the testimonial-18 block
export const testimonialList6 = [
  {
    id: 1,
    name: 'Julia & David',
    review: `Fusce dapibus tellus ac cursus commodo, tortor mauris condimentum nibh fermentum massa, justo sit
    amet vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur
    purus.`
  },
  {
    id: 2,
    name: 'Jolene & Andrea',
    review: `Fusce dapibus tellus ac cursus commodo, tortor mauris condimentum nibh fermentum massa, justo sit
    amet vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur
    purus.`
  },
  {
    id: 3,
    name: 'Eve & Will',
    review: `Fusce dapibus tellus ac cursus commodo, tortor mauris condimentum nibh fermentum massa, justo sit
    amet vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur
    purus.`
  }
];

// used in the testimonial-19 block
export const testimonialList7 = [
  {
    id: 1,
    name: 'Coriss Ambady',
    designation: 'Financial Analyst',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio.`
  },
  {
    id: 2,
    name: 'Cory Zamora',
    designation: 'Marketing Specialist',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio.`
  },
  {
    id: 3,
    name: 'Nikolas Brooten',
    designation: 'Sales Specialist',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio.`
  },
  {
    id: 4,
    name: 'Jackie Sanders',
    designation: 'Investment Planner',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio.`
  },
  {
    id: 5,
    name: 'Laura Widerski',
    designation: 'Sales Specialist',
    review: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio.`
  }
];
